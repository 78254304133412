.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background: #321fdb;
}

.ExcelTable2007 {
  width: 2000px;
  white-space: nowrap;
}

.excel-table-wrap {
  overflow: auto;
  height: 500px;
}

.mobile-resize-mode {
  max-width: 800px;
  margin: 0 auto;
}
.mobile-resize-mode-hearder {
  max-width: 300px;
  margin: 0 auto;
}

/* .ExcelTable2007 tbody > tr:first-child{
    display: none;
} */

.nopayment {
  background: #aaa;
}
.매매 {
  background: #ebdd46;
}
.전세 {
  background: #e17b7b;
}
.월세 {
  background: #73bbe2;
}

.갭투자전세 {
  background: #ee5f16;
}
.갭투자월세 {
  background: #40bd5c;
}
.갭투자동시진행 {
  background: #a349a4;
}

.dong-wrap {
  background: #f8f9fa;
  padding: 15px;
  text-align: center;
  border: 1px solid lightgray;
  border-bottom: 0;
}

.input-dong {
  border: 1px solid lightgray;
  text-align: center;
  font-size: 20px;
  border-radius: 10px;
  font-weight: bold;
  width: 100px;
}

.pointer {
  cursor: pointer;
}

.table-preview {
  background-color: #fff;
}
.table-preview td,
.table-preview th {
  background: white;
  border: 1px solid #dee2e6;
  text-align: center;
}
.table-preview th {
  width: 120px;
  vertical-align: middle;
}

.input-ho {
  width: 50px;
  border: 1px solid lightgray;
  text-align: center;
  border-radius: 10px;
}

.table-calendar td {
  width: 12.5%;
  border-right: 1px solid lightgray;
  vertical-align: middle;
  padding: 0;
  height: 22px;
  border-bottom: 1px solid lightgray;
  vertical-align: top;
}

.table-calendar-m td {
  width: 12.5%;
  border-right: 1px solid lightgray;
  vertical-align: middle;
  padding: 0;
  height: 18px;
  font-size: 12px;
  border-bottom: 1px solid lightgray;
  letter-spacing: -1px;
}
.table-calendar-m th {
  padding: 0;
}

.fixedHeight {
  height: 22px !important;
  line-height: 22px !important;
}

.td-gubun {
  display: flex;
  justify-content: center;
  align-items: center;
}
.h-500 {
  height: 500px;
}
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  -o-transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -moz-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  z-index: 999;
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.pagination > li > a:hover {
  background-color: #218838;
  color: white;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none !important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px !important;
  border-top-right-radius: 4px;
  display: none !important;
}
.pagination {
  display: block;
}

.cash-td {
  cursor: pointer;
  color: #06f;
}
.필로티 {
  background-image: url("./../img/pill.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 58px;
}

.ab-span {
  font-weight: bold;
  position: absolute;
  right: 8px;
  top: 5px;
  cursor: pointer;
}
.map-table,
.map-table td,
th {
  border: 0 !important;
}

.pano-table td,
th {
  border: 1px solid lightgray !important;
}

.float-left {
  float: left;
}

.float-right {
  float: left;
}

.clearfix {
  clear: both;
}

.bm-burger-button {
  position: relative;
}
.search-ul,
.recent-ul {
  background: #fff;
  width: 100%;
  box-shadow: rgb(0 0 0) 2px 2px 5px;
}
.search-ul li,
.recent-ul li {
  padding: 7px 5px;
  border-bottom: 1px solid #ddd;
}
.recent-ul li:not(.top):hover {
  background-color: #ddd;
}
.search-ul li:nth-child(2n) {
  background-color: #ddd;
}
.search-ul li:last-child {
  border-bottom: 0;
}

.checkimg {
  display: inline-block !important;
}

.recent-wrap {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.recent-delete-btn {
  padding: 0.25rem;
  color: #de2727;
  border-radius: 20px;
  border: 1px solid #de2727;
  font-size: 12px;
}

.mo .react-calendar {
  width: 100%;
}
.react-calendar__tile {
  padding: 0.55em 0 !important;
}
.text-small {
  font-size: 11px;
}

.react-calendar__month-view__days {
  align-items: flex-start;
}

.return-btn {
  position: absolute;
  background-color: darkseagreen;
  border-radius: 5px;
  color: #fff;
  padding: 2.5px;
  z-index: 1111;
  cursor: pointer;
}

.detailview-table th{
  width:97px;
  background-color:#D3D3D3 ;
  border-bottom: 1px solid #eee !important;
}

.detailview-table td,th{
  padding: 0.75rem;
  vertical-align: top;
  text-align: center;
  font-size: 13px;
  letter-spacing: -1px;
  vertical-align: middle;
  border:1px solid lightgray;
}
